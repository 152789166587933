<template>
  <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
    <nav class="uk-navbar-container" uk-navbar>
      <div class="uk-navbar-left">
        <div class="ie-logo"># Iconic Engine</div>
        <!--<img src="@/assets/logo-nav.png" width="96" uk-img>-->
      </div>
      <div class="uk-navbar-right">
          <ul class="uk-navbar-nav">
              <themeSwitcher />
              <localeChanger />
              <userDropdown />
          </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import UserDropdown from '@/components/nav/UserDropdown.vue';
import LocaleChanger from '@/components/nav/LocaleChanger.vue';
import ThemeSwitcher from '@/components/nav/ThemeSwitcher.vue';

export default {
  name: 'TopNav',
  components: {
    LocaleChanger,
    UserDropdown,
    ThemeSwitcher
  }
}
</script>
