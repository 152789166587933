<template>
  <li>
    <a href="#" class="icon-link"><i class="uk-margin-small-right" uk-icon="icon: user" ></i> {{ user.firstName }} {{ user.lastName }} <i uk-icon="chevron-down" class="uk-margin-small-left"></i></a>
    <div class="uk-dropdown uk-padding-remove" uk-dropdown="mode: hover; pos: bottom-right; offset: 0; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
      <ul class="uk-nav uk-dropdown-nav uk-text-left uk-padding-small">
        <li class="uk-nav-header">{{ $t('TopNavBar.Settings') }}</li>
        <li><router-link :to="{ name: 'UserProfile' }">{{ $t('TopNavBar.Profile') }}</router-link></li>
        <li><router-link :to="{ name: 'UserPermissions' }">{{ $t('TopNavBar.Permissions') }}</router-link></li>
        <li class="uk-nav-divider"></li>
        <li><a href="#logout-modal" uk-toggle>{{ $t('TopNavBar.Logout') }}</a></li>
      </ul>
      <div id="logout-modal" uk-modal>
        <div class="uk-modal-dialog">
          <div class="uk-modal-body">{{$t( 'Generic.Messages.ConfirmLogout')}} <em>{{$t('TopNavBar.Logout').toLowerCase() }}</em> ?</div>
          <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">{{$t( 'Actions.Cancel')}}</button>
            <button class="uk-button uk-button-primary uk-margin-small-left uk-modal-close" autofocus="" @click="confirmLogout">{{$t('TopNavBar.Logout')}}</button>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UserDropdown',
  computed: {
    ...mapState({
      user: state => state.venom.user
    })
  },
  methods: {
    confirmLogout() {
      this.$store.dispatch("logoutUser");
      this.$router.push({ name: 'Logout'})
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-link:focus {
  color: var(--white-color);
}
.icon-link:hover {
  color:#252525;
}
</style>
