<template>
  <div class="footer">
    <div class="uk-grid-small uk-child-width-expand@s uk-text-right" uk-grid>
      <div class="uk-text-left">
        <img alt="App Logo" class="footer-logo" src="@/assets/logo-nav.png" width="60" height="40" />
      </div>
      <div>
      </div>
      <div class="uk-text-center">
        <small class="uk-text-muted">Copyright &copy; 2023 Iconic Engine, Inc. All Rights Reserved. <i uk-icon="icon: git-branch; ratio: 0.8"></i></small>
        &nbsp;&nbsp;&nbsp;
        <small class="uk-text-muted">Version: {{ version }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBar',
  computed: {
    version: function() {
      return process.env.APP_VERSION;
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  position: fixed;
  bottom: 8px;
  
  img {
    margin-left: 60px;
  }
  
  small {
    font-size: 10px;
  }
}
</style>
