<template>
  <li>
    <a href="#" aria-label="Locale" class="icon-link">
      <i uk-icon="icon: world" class="uk-margin-small-right"></i>
      <span v-if="supportedLocales[$i18n.locale]" class="flag" style="margin-top: -5px;">
        <img :src="supportedLocales[$i18n.locale].image" width="20" height="17" :alt="supportedLocales[$i18n.locale].name" />
      </span>
    </a>
    <div id='locale-changer-dropdown' class="uk-dropdown uk-padding-small" uk-dropdown="mode: hover; pos: bottom-right; offset: 0; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
      <ul class="uk-nav uk-dropdown-nav uk-text-left">
        <li 
          v-for="(lang, i) in Object.keys(locales)" 
          :key="i"
        >
          <div class="uk-flex uk-flex-between uk-margin-small-bottom uk-width-expand"  style="cursor: pointer;" @click="changeLocale(lang)">
            <span :class="{ 'uk-text-bold' : lang === $i18n.locale }" style="color: #999;">{{ locales[`${lang}`].name }}</span>
            <span><img class="flag uk-margin-small-left" :src="locales[`${lang}`].image" width="20" height="17" :alt="locales[`${lang}`].name" /></span>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
  name: 'LocaleChanger',
  data () {
    return {
      locales: {
        'en-US': { name: 'English', image: '/img/flags/us.svg' },
        'de-DE': { name: 'Deutsch', image: '/img/flags/de.svg' },
        'fr-FR': { name: 'Français', image: '/img/flags/fr.svg' },
        'es-ES': { name: 'Espanol ', image: '/img/flags/es.svg' },
        'pt-PT': { name: 'Português ', image: '/img/flags/pt.svg' }
      },
      supportedLocales: {
        'en-US': { name: 'English', image: '/img/flags/us.svg' },
        'de-DE': { name: 'Deutsch', image: '/img/flags/de.svg' },
        'fr-FR': { name: 'Français', image: '/img/flags/fr.svg' },
        'es-ES': { name: 'Espanol ', image: '/img/flags/es.svg' },
        'pt-PT': { name: 'Português ', image: '/img/flags/pt.svg' }
      }
    }
  },
  mounted() {
    const selectedLocale = localStorage.getItem('selectedLocale');
    if (selectedLocale) {
      this.$i18n.locale = selectedLocale;
    }
  },
  created() {
    if ( !this.locales[this.$i18n.locale] ) {
      this.$i18n.locale = 'en-US';
    }
  },
  methods: {
    changeLocale (locale) {
      localStorage.setItem('selectedLocale', locale);
      this.$i18n.locale = locale;
      const localeLabel = this.locales[`${locale}`].name;
      Notification.showNotification(this.$t('TopNavBar.LocaleChanged'), `${this.$t('TopNavBar.LocaleChangedMessage')} ${localeLabel}`)
    }
  },
}
</script>

<style lang="scss" scoped>
#locale-changer-dropdown {
  min-width: 120px!important;
}
.flag {
  font-size: 24px;
}
.flag img {
  width: 20px;
  padding-left: 3px;
}
li:first-child {
  margin-bottom: 10px;
}
.icon-link:focus {
  color: var(--white-color);
}
.icon-link:hover {
  color:#252525;
}
</style>
