<template>
  <li>
    <a href="#" @click="changeMode();" aria-label="Theme" class="icon-link"><i uk-icon="icon: paint-bucket"></i></a>
  </li>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
  name: 'ThemeSwitcher',
  data() {
    return {
      theme: 'dark' 
    }
  },
  methods: {
    changeMode: function() {
      this.theme = (this.theme === 'dark') ? 'light' : 'dark';
      this.$cookies.set('theme', this.theme, null, null, null, null, 'Strict');
      this.$store.commit('setTheme', this.theme );
      document.body.setAttribute('theme', this.theme);
      Notification.showNotification(this.$t('Theme.ThemeChanged'), this.$t('Theme.ThemeChangedDesc').replace('__theme__', this.theme));
    }
  },
  mounted() {
    this.theme = this.$cookies.get('theme') || this.theme;
    this.$store.commit( 'setTheme', this.theme );
  }
}
</script>

<style lang="scss" scoped>
.icon-link:focus {
  color: var(--white-color);
}
.icon-link:hover {
  color:#252525;
}

</style>
